import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';

class SelectBox extends React.PureComponent {

    constructor(props) {

        super(props);

        this.unfilteredItems = List(this.props.items);

        this.state = {
            open: false,
            title: '',
            value: '',
            inputValue: '',
            items: this.unfilteredItems
        };

        this.container = React.createRef();
        this.input = React.createRef();

    }

    componentDidMount() {

        if(this.props.value !== '') {
            this.selectValue(this.props.value);
        }

        document.addEventListener('click', this.onClickedDocument, false);

    }

    componentDidUpdate(prevProps) {

        const itemsChanged = JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items)
        const valueChanged = prevProps.value !== this.props.value

        if(valueChanged && !itemsChanged) {
            this.selectValue(this.props.value)
            return;
        }

        if(!itemsChanged) {
            return;
        }

        this.reset()

        this.unfilteredItems = List(this.props.items);

        this.setState({items: this.unfilteredItems}, () => {
            this.selectValue(this.props.value);
        });

    }

    componentWillUnmount() {

        document.removeEventListener('click', this.onClickedDocument, false);

    }

    onClickedDocument = (e) => {

        if(this.container.current.contains(e.target)) {
            return;
        }

        const update = {open: false, items: this.unfilteredItems};

        if(this.state.open && this.state.inputValue !== this.state.title) {
            update['inputValue'] = this.state.title;
        }

        if(this.state.open) {
            this.setState(update);
        }

    };

    toggleDropdown = () => {

        if(this.props.disabled) {
            return;
        }

        const open = !this.state.open
        const update = {open};

        if(!this.state.open) {
            update['inputValue'] = '';
        }

        this.setState(update);
        if(open) {
            this.input.current.focus();
        }

    };

    selectValue = (value) => {

        const index = this.state.items.findIndex(item => item.value === value);

        if(index >= 0) {
            this.selectItem(index);
        } else {
            this.reset()
        }

    };

    selectItem = (idx) => {

        const item = this.state.items.get(idx);

        this.setState({value: item.value, title: item.title, inputValue: item.title, items: this.unfilteredItems});

        if(this.props.onChange) {
            this.props.onChange(item);
        }

    };

    inputChange = (e) => {

        if (this.props.disabled) {
            return;
        }

        const value = e.target.value;
        const update = {inputValue: value, open: true};

        if(this.unfilteredItems) {

            if(value.length > 0) {
                update['items'] = this.unfilteredItems.filter(item => item.title.toLowerCase().indexOf(value.toLowerCase(), 0) >= 0);
            } else {
                update['items'] = this.unfilteredItems;
            }

        }

        this.setState(update);

    };

    onKeyUp = (e) => {

        if(e.key !== 'Escape') {
            return;
        }

        this.setState({open: false});

    };

    renderHiddenInput () {

        if(this.state.value !== '' && !this.state.items.find(item => item.value === this.state.value)) {
            return null;
        }

        return (
            <input type='text' readOnly={true} className='hidden-field' name={this.props.name} value={this.state.value} required={this.props.required} />
        )

    }

    renderDropdown() {

        if(!this.state.open || this.state.items.isEmpty()) {
            return null;
        }

        return (
            <ul className='select-box-list'>
                {this.props.includeEmpty && <li onClick={() => { this.reset(); if(this.props.onChange) { this.props.onChange({value: null, title: ''}) } }}>&nbsp;</li>}
                {this.state.items.map((item, idx) => <li key={idx} onClick={() => this.selectItem(idx)}>{ item.title }</li>)}
            </ul>
        );

    }

    reset() {
        this.setState({title: '', value:'', inputValue: ''});
    }

    render() {

        const dropDown = this.renderDropdown();
        const iconClass = dropDown ? 'fa-light fa-chevron-up' : 'fa-light fa-chevron-down';

        return (
            <div className={this.props.disabled ? 'select-box disabled' : 'select-box'} ref={this.container} onClick={this.toggleDropdown}>
                <div className='select-box-selection'>
                    { !this.props.disableInput &&
                        <input ref={this.input} id={this.props.id} className='select-box-input' disabled={this.props.disabled} type='text' value={this.state.inputValue} required={this.props.required} placeholder={this.props.placeholder} onChange={this.inputChange} onKeyUp={this.onKeyUp} />
                    }
                    { this.props.disableInput &&
                        <div className='select-box-input'>{this.state.inputValue}</div>
                    }
                    <span className='select-box-icon'><i className={iconClass} /></span>
                </div>
                {!this.props.disabled &&
                    <React.Fragment>
                        { dropDown }
                        { this.renderHiddenInput() }
                    </React.Fragment>
                }
            </div>
        )

    }

}

SelectBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    items: PropTypes.array,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disableInput: PropTypes.bool,
    disabled: PropTypes.bool,
    includeEmpty: PropTypes.bool
};

SelectBox.defaultProps = {
    value: '',
    required: false,
    disableInput: false,
    disabled: false
};

export default SelectBox;