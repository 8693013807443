// noinspection JSVoidFunctionReturnValueUsed

import HUI from './hui';

export function disableWheelInput(e) {
    e.target.blur()
    e.stopPropagation()

    setTimeout(() => {
        e.target.focus()
    }, 0)
}

export default (() => {

    document.addEventListener('turbolinks:load', () => {
        HUI.on(document.body, 'wheel', 'input[type="number"]', disableWheelInput);
    }, false);

})();