import React, {useEffect, useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import axios from 'axios';
import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import { DateTime } from 'luxon';
import {useFloating, useClick, useFocus, useInteractions, offset} from '@floating-ui/react';
import SelectBox from './SelectBox';
import Modal, {Dialog} from './Modal';
import {disableWheelInput} from "../scripts/form_utilities";

const getFullName = (entity) => entity.title ? `${entity.title} ${entity.firstName} ${entity.lastName}` : `${entity.firstName} ${entity.lastName}`;

axios.interceptors.response.use(response => response, error => {
    if(error.response) {
        const {status} = error.response

        if(status === 401) {
            alert('Fehlende Berechtigung um diese Aktion durchzuführen')
        }
    } else if (error.request) {
        console.error(error.request)
    } else {
        console.error(error.message)
    }

    return Promise.reject(error)
})

const Commissioning = ({commissioning}) => {
    if(!commissioning) {
        return null
    }

    return (
        <div key={commissioning.id} className='form-group'>
            <label>{commissioning.firstName} {commissioning.lastName}</label>
            <div className='input-group'>
                <div className='input-group-prepend'>
                    <span className='input-group-text'>Abrechnung</span>
                </div>
            <input className='form-control' type='number' name='percentage' required={true} min={0} max={100} step={5} defaultValue={commissioning.percentage} onWheel={disableWheelInput}/>
                <div className='input-group-append'>
                    <span className='input-group-text'>%</span>
                </div>
            </div>
        </div>
    )
}

Commissioning.propTypes = {
    commissioning: PropTypes.object
}

const Substitute = ({isEditable, showLink, isFuture, isToday, onClickRemove, ...props}) => {
    const fullName = getFullName(props)

    return (
        <div className='lecturer substitute mt-1'>
            <span className='initials' title={fullName}>
                {fullName}
                {showLink &&
                    <a href={props.link}>
                        <i className='fa-light fa-search ml-1'/>
                    </a>
                }
            </span>
            { isEditable &&
                <span className='controls'>
                    {(isFuture || isToday) &&
                        <i className='fa-light fa-trash ml-1' title='Dozent entfernen' onClick={onClickRemove}/>
                    }
                </span>
            }
        </div>
    )
}

Substitute.propTypes = {
    isEditable: PropTypes.bool.isRequired,
    showLink: PropTypes.bool.isRequired,
    isFuture: PropTypes.bool.isRequired,
    isToday: PropTypes.bool.isRequired,
    onClickRemove: PropTypes.func,
    link: PropTypes.string,
};

const DropDown = ({trigger, children}) => {
    if(!trigger) { return null }

    const {context, refs, strategy, x, y} = useFloating({
        open: true, placement: trigger.scope === 'block' ? 'right' : 'bottom', middleware: [offset(5)]
    })

    const {getReferenceProps, getFloatingProps} = useInteractions([
        useClick(context, {toggle: true}),
        useFocus(context)
    ])


    useLayoutEffect(() => {
        refs.setReference(trigger.originalTarget)
    }, [trigger])

    return (
        <React.Fragment>
            <span style={{background: 'none', border: 'none', color: '#fff'}} ref={refs.setReference} {...getReferenceProps({
                onClick(e) { e.stopPropagation() }
            })}>
            </span>
            {open &&
                <div ref={refs.setFloating} style={{minWidth: '150px', position: strategy, top: y ?? 0, left: x ?? 0, zIndex: 9}} {...getFloatingProps()}>
                    {children(trigger.scope)}
                </div>
            }
        </React.Fragment>
    )

}

DropDown.propTypes = {
    trigger: PropTypes.object,
    children: PropTypes.func
}

const Lecturer = ({hasSubstitute, showLink, onClick, ...props}) => {
    const fullName = getFullName(props)

    const nameStyle = hasSubstitute ? {textDecoration: 'line-through'} : {}
    const classes = ['lecturer'];

    if (props.state === 'selected') {
        classes.push('selected')
    } else if (hasSubstitute) {
        classes.push('substituted')
    }

    return (
        <div className={classes.join(' ')} onClick={onClick}>
            <span className='initials' title={fullName}>
                { props.state === 'selected' &&
                    <i className='fa-light fa-circle-info ml-1 mr-2' title='Unterschrift für Beauftragung ist ausstehend'/>
                }
                { props.isAuditor &&
                    <i className='fa-solid fa-shield-check ml-1 mr-2' title='Auditor'/>
                }
                <span style={nameStyle}>
                    {fullName}
                </span>
                { showLink &&
                    <a href={props.link} onClick={(e) => e.stopPropagation()}>
                        <i className='fa-light fa-search ml-2'/>
                    </a>
                }
                { 100 > props.percentage && !props.isAuditor &&
                    <span className={'badge badge-info ml-2'} title={`Abrechnung ${props.percentage}%`}>{`${props.percentage}%`}</span>
                }
            </span>
        </div>
    )
}

Lecturer.propTypes = {
    isEditable: PropTypes.bool.isRequired,
    hasSubstitute: PropTypes.bool.isRequired,
    showLink: PropTypes.bool.isRequired,
    link: PropTypes.string,
    percentage: PropTypes.number,
    onClick: PropTypes.func,
    state: PropTypes.string,
    isAuditor: PropTypes.bool
};

const LecturerDialog = ({blockId, onClose, onSave}) => {
    const [lecturers, setLecturers] = useState([])
    const [isAuditor, setIsAuditor] = useState(false)

    useEffect(() => {
        (async () => {
            let url = new URL(`/administration/blocks/${blockId}/qualifications`, location.origin)

            if(isAuditor) { url.searchParams.set('auditors', isAuditor) }

            let result = await axios.get(url.href, {
                headers: {'Accept': 'application/json'},
                validateStatus: status => status >= 200 && status < 500
            });

            if(result.status === 200) {
                setLecturers(result.data.map(lecturer => ({value: lecturer.id, title: getFullName(lecturer)})))
            }
        })()

        return () => {}
    }, [isAuditor]);

    return (
        <Dialog onClose={onClose} title='Dozent hinzufügen'>
            <form onSubmit={onSave}>
                <div className="checkbox mb-3">
                    <input id="is_auditor" type="checkbox" name="is_auditor" value={isAuditor}
                           onChange={() => setIsAuditor(!isAuditor)}
                    />
                    <label htmlFor="is_auditor">Auditor</label>
                </div>
                <div className="form-group">
                    <label htmlFor="is_auditor">Dozent</label>
                    <SelectBox required={true} name="lecturer_id" items={lecturers}/>
                </div>
                {!isAuditor &&
                    <div className="form-group">
                        <label>Anteil (%)</label>
                        <input className="form-control" type="number" name="percentage" required={true} min={0}
                               max={100} step={5} defaultValue={100} onWheel={disableWheelInput}
                        />
                    </div>
                }
                <button type="submit" className="btn btn-primary">Speichern</button>
            </form>
        </Dialog>
    )

}

LecturerDialog.propTypes = {
    blockId: PropTypes.number,
    onClose: PropTypes.func,
    onSave: PropTypes.func
}

class ModulePlanner extends React.PureComponent {

    state = {
        block: {},
        modules: List(),
        sortableOrder: [],
        clickTarget: null,
        isSortable: true,
        lecturers: [],
        action: null
    };

    modules = React.createRef();

    async componentDidMount() {
        const [block, modules, lecturers, isSortable] =  await this.loadData();

        this.sortable = Sortable.create(this.modules.current, {
            handle: '.drag-target',
            filter: '.drag-disabled',
            onMove: event => {
                return !event.related.classList.contains('drag-disabled');
            },
            onEnd: this.onOrderChanged
        });

        this.setState({block, modules, lecturers, isSortable, sortableOrder: this.sortable.toArray()});
    }

    componentWillUnmount() {
        this.sortable.destroy();
    }

    loadData = async () => {
        let result = await axios.get(location.href, {headers: {'Accept': 'application/json'}});
        let block = {};
        let modules = List();
        let lecturers = [];

        if(result.status === 200) {

            block = (({ id, name, duration, blockVersionId, blockId }) => ({ id, name, duration, blockVersionId, blockId }))(result.data);
            modules = List(result.data.modules);

        }

        if(this.props.showLecturers) {
            result = await axios.get('/administration/lecturers', {
                headers: {'Accept': 'application/json'},
                validateStatus: status => status >= 200 && status < 500
            });

            if(result.status === 200) {
                lecturers = result.data.map(lecturer => ({value: lecturer.id, title: getFullName(lecturer)}))
            }
        }

        const isSortable = modules.find(m => m.isLinked) == null

        return [block, modules, lecturers, isSortable]
    };

    refreshData = async () => {
        const [block, modules, lecturers, isSortable] =  await this.loadData();

        this.setState({block, modules, lecturers, isSortable});
    }

    moveModule = async (module, position) => {

        const response = await axios.patch(`/administration/topic_instances/${module.id}/instance_assignments/${module.instanceAssignmentId}`, {position: position}, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        }).catch(() => {
            this.sortable.sort(this.state.sortableOrder); // Restore original order on errors
        });

        if(response.status >= 200 && response.status < 300) {
            this.setState({sortableOrder: this.sortable.toArray()})
        }

    };

    onOrderChanged = async (e) => {

        if(e.oldIndex === e.newIndex) {
            return;
        }

        const module = this.state.modules.get(e.oldIndex);

        await this.moveModule(module, e.newIndex + 1);
        await this.refreshData();

    };

    retractCommission = async (e) => {

        e.stopPropagation();

        await this.scopedAction(async (topicInstanceId, schedulingId, commissionings) => {

            for(const commission of commissionings) {
                await axios.delete(`/administration/topic_instances/${topicInstanceId}/schedulings/${schedulingId}/commissionings/${commission}`, {
                    headers: {
                        'Accept': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                    }
                });
            }

        }, null);

        this.setState({clickTarget: null});

        await this.refreshData();

    }

    toggleDropDown = (e, data, scope) => {
        
        if(this.state.clickTarget !== null && e.target === this.state.clickTarget.originalTarget) {
            this.setState({clickTarget: null});
            return;
        }

        e.stopPropagation();

        let clickTarget = {};

        clickTarget.originalTarget = e.target;
        clickTarget.data = data;
        clickTarget.scope = scope;

        this.setState({clickTarget});

    };

    createCommission = async (topicInstanceId, schedulingId, commissionings, data) => {

        if(!Array.isArray(commissionings) && !data.has('is_auditor')) {
            data.append('substitutes', commissionings);
        }

        await axios.post(`/administration/topic_instances/${topicInstanceId}/schedulings/${schedulingId}/commissionings`, data, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

    }

    updateCommission = async (topicInstanceId, schedulingId, commissionings, data) => {

        await axios.patch(`/administration/topic_instances/${topicInstanceId}/schedulings/${schedulingId}/commissionings/${commissionings[0]}`, data, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })

    }

    scopedAction = async (action, data, filter) => {

        const {clickTarget} = this.state;

        if (clickTarget.scope === 'substitute' && this.state.action === 'substitute') {

            const {module, scheduling, commissioning} = clickTarget.data;

            await action(module.id, scheduling.id, commissioning.id, data);

        } else if(clickTarget.scope === 'block') {

            for(const module of this.state.modules) {

                const schedulings = filter ? module.schedulings.filter(filter) : module.schedulings;

                for(const scheduling of schedulings) {
                    await action(module.id, scheduling.id, scheduling.commissionings.map(c => c.id), data);
                }
            }

        } else if (clickTarget.scope === 'module') {

            const {module} = clickTarget.data;

            const schedulings = filter ? module.schedulings.filter(filter) : module.schedulings;

            for(const scheduling of schedulings) {
                await action(module.id, scheduling.id, scheduling.commissionings.map(c => c.id), data);
            }

        }  else {

            const {module, scheduling} = clickTarget.data;

            const commissionings = clickTarget.scope === 'substitute' ? [clickTarget.data.commissioning.id] : scheduling.commissionings.map(c => c.id)

            await action(module.id, scheduling.id, commissionings, data);

        }

    }

    onSubmitLecturer = async (e) => {

        e.preventDefault()

        if(!e.target.reportValidity()) {
            return
        }

        const data = new FormData(e.target);

        const filter = data.has('is_auditor') ? null : s => s.commissionings.length === 0

        await this.scopedAction(this.createCommission, data, filter)

        this.setState({clickTarget: null, action: null})

        await this.refreshData()

    }

    onSubmitCommissioning = async (e) => {

        e.preventDefault()

        if(!e.target.reportValidity()) {
            return
        }

        const data = new FormData(e.target)

        await this.scopedAction(this.updateCommission, data)

        this.setState({clickTarget: null, action: null})

        await this.refreshData()

    }

    render() {

        return (
            <div className='module-planner-container'>
                <div className='module-planner'>
                    <div className='block' onClick={(e) => this.toggleDropDown(e, {block: this.state.block}, 'block')}>{this.state.block.name}</div>
                    <div className='modules' ref={this.modules}>
                        {

                            this.state.modules.map(module => {

                                return (
                                    <div key={module.id} className={DateTime.fromISO(module.startDate) > DateTime.local() ? 'module' : 'module drag-disabled' } style={{flexBasis: `${(module.duration / this.state.block.duration) * 100}%`}}>
                                        <div className='details-wrapper'>
                                            {this.props.editable && DateTime.fromISO(module.startDate) > DateTime.local() && this.state.isSortable &&
                                                <div className='drag-target'>
                                                    <i className='fa-light fa-arrows-v'/>
                                                </div>
                                            }
                                            <div className='details'>
                                                <div className='title'>
                                                    <span onClick={(e) => this.toggleDropDown(e, {module}, 'module')}>{module.name}</span>
                                                    {this.props.showTopicInstanceLink &&
                                                        <a href={module.link}>
                                                            <i className='fa-light fa-search ml-2'/>
                                                        </a>
                                                    }
                                                </div>
                                                <span className='badge badge-info'>{module.duration} {module.duration === 1 ? 'Tag' : 'Tage'}</span>
                                                {module.isNotGraded &&
                                                    <span className='badge badge-warning ml-1'>Keine Benotung</span>
                                                }
                                                {module.isLinked &&
                                                    <span className='badge badge-info ml-1' title='Zusammengelegtes Modul'>
                                                        <i className='fa-light fa-link'/>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        <div className='units'>
                                            {

                                                module.schedulings.map((scheduling, idx) => {

                                                    const scheduledDate = DateTime.fromISO(scheduling.date);
                                                    const isFuture = scheduledDate > DateTime.local();
                                                    const isToday = scheduledDate.toISODate() === DateTime.local().toISODate();

                                                    let classes = scheduling.commissionings.length === 0 ? 'unit unassigned' : 'unit'

                                                    if(isFuture) {
                                                        classes = `${classes} future`
                                                    }

                                                    return(
                                                        <div className={classes} key={idx} onClick={(e) => { if(!scheduling.lecturer) { this.toggleDropDown(e, {module, scheduling}, 'scheduling') } }}>
                                                            <span className='unit-date'>{scheduledDate.toFormat('dd. MMM')}</span>
                                                            <div className='lecturers'>
                                                                {
                                                                    scheduling.commissionings.sort(this.sortByCommissioningKind).map(commissioning => (

                                                                        <Lecturer
                                                                            key={commissioning.id}
                                                                            isEditable={this.props.editable && (isFuture || isToday)}
                                                                            hasSubstitute={commissioning.substitutedById !== null}
                                                                            showLink={this.props.showLecturerLink}
                                                                            {...commissioning}
                                                                            onClick={(e) => this.toggleDropDown(e, {module, scheduling, commissioning}, 'substitute')}
                                                                        />

                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    </div>
                                );

                            })
                        }
                    </div>
                </div>
                <Modal isVisible={this.state.action !== null && this.state.action !== 'edit'} onVisible={this.loadQualifiedLecturers}>
                    <LecturerDialog blockId={this.state.block.blockId} onClose={() => this.setState({clickTarget: null, action: null})} onSave={this.onSubmitLecturer} />
                </Modal>
                <Modal isVisible={this.state.action !== null && this.state.action === 'edit'}>
                    <Dialog onClose={() => this.setState({clickTarget: null, action: null})} title='Einplanung bearbeiten'>
                        <form onSubmit={this.onSubmitCommissioning}>
                            { this.state.clickTarget &&
                                 <Commissioning commissioning={this.state.clickTarget.data.commissioning} />
                            }
                            <button type='submit' className='btn btn-primary'>Speichern</button>
                        </form>
                    </Dialog>
                </Modal>
                { this.state.action === null &&
                    <DropDown trigger={this.state.clickTarget}>
                        {scope => (
                            <div className='list-group' >
                                { scope !== 'substitute' && <button type='button' className='list-group-item list-group-item-action' style={{color: '#888da8'}} onClick={() => this.setState({action: 'add'})}><i className='fa-light fa-user-plus' style={{marginRight: '5px'}} />Dozent hinzufügen</button> }
                                { scope === 'substitute' && !this.state.clickTarget.data.commissioning.isAuditor && this.state.clickTarget.data.commissioning.substitutedById === null && <button type='button' className='list-group-item list-group-item-action' style={{color: '#888da8'}} onClick={() => this.setState({action: 'substitute'})}><i className='fa-light fa-bandage' style={{marginRight: '5px'}} />Vertretung hinzufügen</button> }
                                { scope === 'substitute' && !this.state.clickTarget.data.commissioning.isAuditor && <button type='button' className='list-group-item list-group-item-action' style={{color: '#888da8'}} onClick={() => this.setState({action: 'edit'})}><i className='fa-light fa-pencil' style={{marginRight: '5px'}} />Einplanung bearbeiten</button> }
                                { scope !== 'scheduling' && <button type='button' className='list-group-item list-group-item-action' style={{color: '#888da8'}} onClick={this.retractCommission}><i className='fa-light fa-trash' style={{marginRight: '5px'}} />Einplanung entfernen</button> }
                            </div>
                        )}
                    </DropDown>
                }
            </div>
        );

    }

}

ModulePlanner.propTypes = {
    editable: PropTypes.bool.isRequired,
    showTopicInstanceLink: PropTypes.bool.isRequired,
    showLecturerLink: PropTypes.bool.isRequired,
    showLecturers: PropTypes.bool.isRequired
};

ModulePlanner.defaultProps = {
    editable: false,
    showTopicInstanceLink: false,
    showLecturerLink: false
}

export default ModulePlanner;